import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import moment from "moment";
import { FHInlineSvg } from "../../../components/SemanticTheme/FHInlineSvg";
import { Header } from "../../../components/SemanticTheme/Header";
import { HeaderButton } from "../../../components/SemanticTheme/HeaderButton";
import { SubNavLinkItem } from "../../../components/SemanticTheme/SubNavigationBar";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { ButtonStyle } from "../../../components/SemanticTheme/FHButton";
import {
  LocationsDropdownItem,
  LocationsLineItems,
} from "../../../components/SemanticTheme/BookingForm";
import { Content, ContentLink } from "../../../types/Content";
import { FHButtonDropdownProps } from "../../../components/SemanticTheme/FHButtonDropdown";
import {
  updateAbsoluteLink,
  useGenerateResponsiveImageUrl,
} from "../../../components/Utils";
import { useRouter } from "next/router";
import LocationMapComponent from "./../LocationMapTemplate";
import { FHModal } from "../../../components/SemanticTheme/FHModal";
import { RichContentArea } from "../../../components/SemanticTheme/RichContentArea";
import { DynamicBookingWidget } from "./../DynamicComponents";
import { maxImgWidth } from "../../../utils/common";
import { withContent } from "../../../utils/TemplateWithContent";
import {
  getLocationsAction,
  createLogoutFromSession,
  resetExperimentAddressData,
  setActiveExperiments,
} from "@/store/actions";
import {
  useIsAuthenticated,
  useIsAuthenticating,
  useLoginSelector,
  useLocationsSelector,
  useSiteSettingsSelector,
} from "@/store/selectors";
import { resetBookingFunnel } from "src/utils/postsst";
import { ComponentSharedChildLinks } from "src/graphql/generated-strapi/types";
import BannerSticky from "@components/SemanticTheme/BannerSticky";
import { FHNextImage } from "@components/SemanticTheme/FHNextImage";
import { Hidden } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  headerBtn: {
    marginRight: theme.spacing(0.5),
  },
  btnContent: {
    display: "flex",
  },
  containedButton: {
    color: theme.palette.background.paper,
    borderColor: theme.palette.secondary.main,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      color: theme.palette.background.paper,
      borderColor: theme.palette.secondary.main,
      backgroundColor: theme.palette.secondary.main,
    },
  },
  outlinedButton: {
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    "&:hover": {
      color: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
    },
  },
}));

type HeaderLink = {
  primaryCallToActionText: string;
  primaryCallToActionLink?: string | undefined;
  headerLinkCards?: HeaderLinkCard[];
  headerSubnavCards?: HeaderSubnavCard[];
  headerSubnavCardsContainers?: any;
  showLocationMap?: boolean;
  isMobileTabbedContent?: boolean;
};

type HeaderLinkCard = {
  title: string;
  image?: string;
  description: string;
  primaryCallToActionText: string;
  primaryCallToActionLink?: string;
  imageQuality?: number;
};

type HeaderSubnavCard = {
  title: string;
  description: string;
  subnavLinks: SubNavigation[] | undefined;
};

type SubNavigation = {
  linkText: string;
  linkURL: ContentLink;
};

enum Column {
  name = "HeaderLocationThirdColumn",
}

type MyAccountMenuItem = {
  label: string;
  icon?: string | undefined;
  uRL?: string | undefined;
};

const desktopLocationMapContent = {
  expandedValue: undefined,
  value: null,
};

export const HeaderComponent = (props) => {
  const stickyBanner = props?.stickyBannerProps;
  const hideSubscribeSticky = props?.hideSubscribeSticky;
  const additionalProps = props?.additionalProps;
  const classes = useStyles();
  const isAuthenticated: boolean = useIsAuthenticated();
  const isAuthenticating: boolean = useIsAuthenticating();
  const { siteSettings } = useSiteSettingsSelector();
  const { loggedOutRecently: userLoggedOutRecently } = useLoginSelector();
  const { bookableLocations } = useLocationsSelector();
  const [mobileHeaderData, setMobileHeaderData] = useState<
    SubNavLinkItem[] | undefined
  >(undefined);
  const [headerData, setHeaderData] = useState<any>(null);

  const loginLabel = additionalProps?.changeMyBookingsToLoginRegister
    ? "Login/Register"
    : props?.LoginLabel;

  const [tabs, setTabs] = useState({
    locationMapLabel: "Map",
    locationListLabel: "List",
  });

  type ViewAllLocationsProps = {
    label: string;
    url: string;
  };

  const [viewAllLocation, setViewAllLocation] = useState<ViewAllLocationsProps>(
    { label: "", url: "" },
  );

  const router = useRouter();
  const dispatch = useDispatch();
  const [hoverLocationId, setHoverLocationId] = useState<string>("");
  const [sortedLocations, setSortedLocations] = useState<LocationsLineItems>();
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  const blogImageSizes = { xs: 300, sm: 300, md: 300, lg: 300, xl: 300 };
  const [cookies, setCookie] = useCookies(["hideSubscribeSticky"]);
  const [isBrowser, setIsBrowser] = useState(false);
  const imgQuality = props?.ImageQuality || props?.pageImgQuality;
  const excludeSignUpBanner = !!props.additionalProps?.excludeSignUpBanner;
  const excludeOfferFromSignUpBanner =
    !!props.additionalProps?.excludeOfferFromSignUpBanner;

  const numberOfDaysToHideBannerSticky = siteSettings?.bannerStickySettings
    ?.bannerStickyCookieLengthInDays
    ? moment(new Date())
        .add(
          siteSettings?.bannerStickySettings?.bannerStickyCookieLengthInDays,
          "days",
        )
        .toDate()
    : undefined;

  const handleCookie = () => {
    setCookie("hideSubscribeSticky", true, {
      path: "/",
      expires: numberOfDaysToHideBannerSticky,
    });
  };

  const responsiveImage = (
    url: string | undefined,
    maxWidths: maxImgWidth,
    quality?: number,
  ) => {
    return !!url
      ? useGenerateResponsiveImageUrl(url, maxWidths, quality, true)
      : undefined;
  };

  const getViewAllLocationsData = (subnav: any): ViewAllLocationsProps => {
    const locationMapProps = subnav?.locations?.data?.[0]?.attributes;
    const label = locationMapProps?.CompareLocationsCTALabel || "";
    const url =
      updateAbsoluteLink(
        locationMapProps?.CompareLocationsCTAURL?.data?.attributes?.PageRoute,
      ) || "";

    return { label, url };
  };

  useEffect(() => {
    setIsBrowser(typeof window !== "undefined");
    dispatch(
      setActiveExperiments({
        linksFromPumpkinToCharcoal:
          !!props.additionalProps?.linksFromPumpkinToCharcoal,
        showSpecificCabin: !!props.additionalProps?.showSpecificCabin,
        changeTotalPriceColor: !!props.additionalProps?.changeTotalPriceColor,
        hideBookNowButton: !!props.additionalProps?.hideBookNowButton,
        showMovieModal: !!props.additionalProps?.showMovieModal,
        glenstressNaming: !!props.additionalProps?.glenstressNaming,
        excludePayMonthly: !!props.additionalProps?.excludePayMonthly,
        exposeSearchForm: !!props.additionalProps?.exposeSearchForm,
      }),
    );
  }, []);

  useEffect(() => {
    if (userLoggedOutRecently && !isAuthenticated && !isAuthenticating) {
      !!props?.LoginPageURL?.data?.attributes?.PageRoute &&
        router.push(props?.LoginPageURL?.data?.attributes?.PageRoute);
    }
  }, [userLoggedOutRecently]);

  useEffect(() => {
    if (bookableLocations && bookableLocations.length > 0) {
      const sorted = bookableLocations.reduce(
        (
          prevLocations: { [key: string]: LocationsDropdownItem },
          location: LocationsDropdownItem,
        ) => ({
          ...prevLocations,
          [(location?.name || "").toLowerCase().replaceAll(" ", "")]: location,
        }),
        {},
      );
      setSortedLocations(sorted);
    } else {
      dispatch(
        getLocationsAction({
          allLocations: false,
          includeCampingLocations: false,
        }),
      );
      return;
    }
  }, [bookableLocations]);

  const getHeaderData = () => {
    const HeaderExpandedLinks =
      props?.HeaderLinks?.data &&
      (props?.HeaderLinks?.data).map((item) => {
        const link = item?.attributes?.PrimaryCallToActionRef?.data
          ?.attributes as Content;
        const linkUrl = link ? link?.PageRoute : undefined;
        const isMobileTabbedContent = item?.attributes?.IsMobileTabbedContent
          ? (item?.attributes?.IsMobileTabbedContent as boolean)
          : false;
        if (isMobileTabbedContent) {
          setTabs({
            locationMapLabel:
              String(item?.attributes?.LocationMapLabelInMobile) || "Map",
            locationListLabel:
              String(item?.attributes?.LocationListLabelInMobile) || "List",
          });
        }
        let showLocationMap = false;
        // ContentArea - HeaderLinkCards
        //TODO: needed to be removed headerLinkCards related code while working with mobile code.
        const headerLinkCards = item?.attributes?.HeaderLinkCards?.data;

        const expandedHeaderLinkCards = Array.isArray(headerLinkCards)
          ? headerLinkCards.map<HeaderLinkCard>((card) => {
              const cardlink =
                card?.attributes?.PrimaryCallToActionRef?.data?.attributes;
              const cardlinkUrl = cardlink ? cardlink?.PageRoute : undefined;

              return {
                title: card?.attributes?.Title as string,
                image: card?.attributes?.Image?.data?.attributes?.url,
                description: card?.attributes?.Description as string,
                primaryCallToActionText:
                  card?.attributes?.PrimaryCallToActionText,
                primaryCallToActionLink: cardlinkUrl,
                imageQuality: card?.attributes?.ImageQuality,
              };
            })
          : undefined;

        // ContentArea - HeaderSubnavCards
        //TODO: needed to be removed headerLinkCards related code while working with mobile code.

        const headerSubnavCards = item?.attributes?.HeaderSubnavCards?.data;
        const expandedHeaderSubnavCards =
          headerSubnavCards && Array.isArray(headerSubnavCards)
            ? headerSubnavCards?.map((subnav) => {
                const links = subnav?.attributes?.SubnavLinks?.map((link) => {
                  return {
                    linkText: link?.LinkText as string,
                    linkURL: link?.LinkURL?.data?.attributes?.PageRoute,
                  };
                });

                return {
                  title: subnav?.attributes?.Title as string,
                  description: subnav?.attributes?.Description as string,
                  subnavLinks: links,
                };
              })
            : undefined;
        // ContentArea - HeaderSubnavCardsContainer
        const headerSubnavCardsContainer =
          item?.attributes?.HeaderSubnavCardsContainers?.data;
        const expandedHeaderSubnavCardsContainer =
          headerSubnavCardsContainer &&
          Array.isArray(headerSubnavCardsContainer)
            ? headerSubnavCardsContainer.map((subNavContainer) => {
                const subNavigationLink =
                  subNavContainer?.attributes?.Cards &&
                  Array.isArray(subNavContainer?.attributes?.Cards)
                    ? (subNavContainer?.attributes?.Cards).map((subnav) => {
                        const subnavData =
                          subnav?.hsubnavs?.data?.[0]?.attributes;
                        if (
                          subnavData?.SubnavLinks &&
                          Array.isArray(subnavData?.SubnavLinks)
                        ) {
                          const links = subnavData.SubnavLinks.map((link) => {
                            return {
                              linkText: link?.LinkText as string,
                              linkURL:
                                link?.LinkURL?.data?.attributes?.PageRoute,
                            };
                          });

                          return {
                            title: subnavData?.Title as string,
                            description: subnavData?.Description as string,
                            subnavLinks: links,
                          };
                        } else {
                          desktopLocationMapContent.expandedValue = subnav;
                          showLocationMap = true;
                          setViewAllLocation({
                            label: getViewAllLocationsData(subnav)?.label,
                            url: getViewAllLocationsData(subnav)?.url,
                          });
                          return false;
                        }
                      })
                    : undefined;
                return subNavigationLink;
              })
            : undefined;
        if (
          headerSubnavCardsContainer &&
          Array.isArray(headerSubnavCardsContainer)
        ) {
          headerSubnavCardsContainer.forEach((subNavContainer) => {
            if (
              subNavContainer?.HeaderSubnavCardsOrLocationMaps &&
              Array.isArray(subNavContainer?.HeaderSubnavCardsOrLocationMaps) &&
              subNavContainer?.name === Column.name
            ) {
              desktopLocationMapContent.value =
                subNavContainer?.HeaderSubnavCardsOrLocationMaps?.[0];
            }
          });
        }
        return {
          primaryCallToActionText: item?.attributes
            ?.PrimaryCallToActionText as string,
          primaryCallToActionLink: linkUrl,
          headerLinkCards: expandedHeaderLinkCards,
          headerSubnavCards: expandedHeaderSubnavCards,
          headerSubnavCardsContainers: expandedHeaderSubnavCardsContainer,
          showLocationMap: showLocationMap,
          isMobileTabbedContent: isMobileTabbedContent,
        };
      });

    const headerDataLink: any =
      HeaderExpandedLinks &&
      HeaderExpandedLinks.map((toplevel: HeaderLink) => {
        let cards,
          links = undefined;
        if (toplevel?.headerLinkCards) {
          cards = toplevel?.headerLinkCards?.map((card) => ({
            title: card?.title,
            imgUrl: responsiveImage(
              card?.image,
              blogImageSizes,
              card?.imageQuality ?? imgQuality,
            ),
            description: card?.description,
            link: {
              label:
                !!props?.additionalProps?.excludePayMonthly &&
                card?.primaryCallToActionText !== "Discover pay monthly"
                  ? ""
                  : card?.primaryCallToActionText,
              url: updateAbsoluteLink(card?.primaryCallToActionLink),
            },
          }));
        }
        if (toplevel?.headerSubnavCards) {
          links = toplevel?.headerSubnavCards.map((card) => ({
            title: card?.title,
            description: card?.description,
            links: card?.subnavLinks?.map((link) => ({
              label: link?.linkText,
              url: updateAbsoluteLink(link?.linkURL?.toString()),
            })),
          }));
        }
        if (toplevel.headerSubnavCardsContainers) {
          links = toplevel.headerSubnavCardsContainers.map(
            (cardsContainer: HeaderSubnavCard[]) => {
              const cardLink = cardsContainer.map((card: HeaderSubnavCard) => {
                // FH_ExcludePayMonthlyOption_FR593
                const expSubnavLinks = !!props?.additionalProps
                  ?.excludePayMonthly
                  ? card?.subnavLinks?.filter(
                      (link) => link?.linkText !== "Pay monthly",
                    )
                  : card?.subnavLinks;
                if (card) {
                  return {
                    title: card.title,
                    description: card.description,
                    links: expSubnavLinks?.map((link: SubNavigation) => ({
                      label: link?.linkText,
                      url: updateAbsoluteLink(link?.linkURL?.toString()),
                    })),
                  };
                }
              });
              return cardLink;
            },
          );
        }
        const linksList =
          links?.length > 0 &&
          links.filter((list: any) => {
            if (list[0]) {
              return list;
            }
          });
        return {
          label: toplevel?.primaryCallToActionText,
          url: updateAbsoluteLink(toplevel?.primaryCallToActionLink),
          showLocationMap: toplevel?.showLocationMap,
          content:
            cards || linksList
              ? {
                  cards,
                  subnavs: linksList,
                }
              : undefined,
        };
      });

    setHeaderData(headerDataLink);

    const mobileHeaderLinks: SubNavLinkItem[] = HeaderExpandedLinks
      ? HeaderExpandedLinks.map((toplevel) => {
          const mobileLinks: SubNavLinkItem[] = [];
          let mobileSubLinks = [];
          let mobileCards: SubNavLinkItem[] = [];
          //TODO: needed to be removed headerLinkCards related code while working with mobile code.
          if (!!toplevel?.headerLinkCards?.length) {
            mobileCards = toplevel?.headerLinkCards.map((card) => ({
              text: card?.title,
              link: card?.primaryCallToActionLink as string,
              isActive: false,
            }));
          }

          if (!!toplevel.headerSubnavCardsContainers?.length) {
            mobileSubLinks = toplevel.headerSubnavCardsContainers.map(
              (cardsContainer: HeaderSubnavCard[]) => {
                const cardLink = cardsContainer.map(
                  (card: HeaderSubnavCard) => {
                    // FH_ExcludePayMonthlyOption_FR593
                    const expSubnavLinks = !!props?.additionalProps
                      ?.excludePayMonthly
                      ? card?.subnavLinks?.filter(
                          (link) => link?.linkText !== "Pay monthly",
                        )
                      : card?.subnavLinks;
                    if (card) {
                      return {
                        isActive: false,
                        text: card.title,
                        description: card.description,
                        link: expSubnavLinks?.map((link: SubNavigation) => ({
                          isActive: false,
                          text: link.linkText,
                          link: link?.linkURL,
                        })),
                      };
                    }
                  },
                );
                return cardLink;
              },
            );
          }

          mobileSubLinks.forEach((links: SubNavLinkItem[]) => {
            if (links.length > 0) {
              links.forEach((link: SubNavLinkItem) => {
                if (link) mobileLinks.push(link);
              });
            }
          });
          if (mobileLinks?.length > 0 || mobileCards.length > 0) {
            return {
              text: toplevel?.primaryCallToActionText,
              link: mobileLinks,
              isActive: false,
              mobileCard: mobileCards,
              isMobileTabbedContent: toplevel?.isMobileTabbedContent,
            } as SubNavLinkItem;
          } else {
            return {
              text: toplevel?.primaryCallToActionText,
              link: toplevel?.primaryCallToActionLink,
              isActive: false,
              isMobileTabbedContent: false,
            } as SubNavLinkItem;
          }
        })
      : [];

    if (!isAuthenticated && loginLabel) {
      mobileHeaderLinks.push({
        text: loginLabel,
        link: props?.LoginPageURL?.data?.attributes?.PageRoute as string,
        isActive: false,
      });
    }
    setMobileHeaderData(mobileHeaderLinks);
  };

  if (
    !!props?.HeaderLinks?.data?.length &&
    headerData == null &&
    mobileHeaderData == null
  ) {
    getHeaderData();
  }

  const expandedMyAccountMenuItems =
    props?.MyAccountMenuItems?.data &&
    (props?.MyAccountMenuItems?.data).map((item) => {
      const icon = item?.attributes?.Icon?.data;
      const iconUrl = icon ? icon?.attributes?.url : undefined;
      const link = item?.attributes?.URL;
      const linkUrl = link ? link?.data?.attributes?.PageRoute : undefined;

      return {
        label: item?.attributes?.Label as string,
        icon: iconUrl,
        uRL: linkUrl,
      };
    });
  const expandedBookNowButton = props?.BookNowButton
    ? props?.BookNowButton?.data.map((item) => {
        return {
          bookNowLabel: item?.attributes?.BookNowLabel,
          bookNowButtonLabel: item?.attributes?.BookNowButtonLabel,
          bookNowButtonTheme: item?.attributes?.BookNowButtonTheme,
          bookingWidgetRef:
            item?.attributes?.BookingWidgetRef?.data?.[0]?.attributes,
        };
      })
    : " ";

  const mapOverrideProps = {
    isWidget: false,
    setHoverLocationId: (id: string) => setHoverLocationId(id),
    hoverLocationId: hoverLocationId,
    showCompareLocations: true,
    showNewLocationTag: !!additionalProps?.showNewLocationTag,
  };

  const locationMap = !!desktopLocationMapContent?.expandedValue?.locations
    ?.data?.length ? (
    <LocationMapComponent
      contentValue={{
        data: desktopLocationMapContent?.expandedValue?.locations?.data?.[0],
      }}
      overrideProps={mapOverrideProps}
      isDarkTheme={!!props?.isGlentressLandingPageExp}
    />
  ) : undefined;

  // TODO: my account button should not be in the list, however design specified logged in items to be shown as a sub level
  // original design has My account and contact us buttons separate and at the bottom
  if (
    props?.HeaderButtons &&
    props?.HeaderButtons?.length > 0 &&
    mobileHeaderData !== undefined
  ) {
    mobileHeaderData.push(
      ...props?.HeaderButtons?.map((btn: ComponentSharedChildLinks) => {
        return {
          text: btn?.LinkText,
          link: btn?.LinkURL?.data?.attributes?.PageRoute,
          isActive: false,
        };
      }),
    );
  } else {
    if (isAuthenticated) {
      const myAccountItemList: SubNavLinkItem[] = (
        expandedMyAccountMenuItems || []
      )
        .filter((item) => typeof item.uRL === "string")
        .map((item) => ({
          text: item?.label,
          link: item?.uRL || "",
          isActive: false,
        }));

      myAccountItemList.push({
        text: props?.LogoutLabel,
        link: () => setLogoutModalOpen(true),
        isActive: false,
      });
      mobileHeaderData !== undefined &&
        mobileHeaderData.push({
          text: props?.MyAccountLabel,
          link: myAccountItemList,
          isActive: false,
        });
    }
  }

  const mobileMenuLinks: SubNavLinkItem[] = expandedMyAccountMenuItems?.map(
    (item: MyAccountMenuItem) => ({
      text: item?.label,
      link: item?.uRL,
      isActive: false,
    }),
  );

  const isContactUs =
    props?.HeaderButtons?.filter(
      (btn) => btn?.LinkText?.toLowerCase() === "contact us",
    )?.length > 0;

  const hideContactUsBtn =
    !!props?.additionalProps?.checkoutExcludeContactUs && isContactUs;

  const createDesktopButtons = () => {
    const buttons = props?.HeaderButtons;
    if (buttons?.length === 0) {
      const loggedInHeaderProps: FHButtonDropdownProps = {
        selectionList: [
          ...(expandedMyAccountMenuItems || [])
            .filter((item: MyAccountMenuItem) => typeof item.uRL === "string")
            .map((item: MyAccountMenuItem) => ({
              id: item.uRL as string,
              label: item.label,
              icon: (
                <FHInlineSvg
                  className={classes.headerBtn}
                  src={item?.icon as string}
                  size={"24px"}
                  color={"white"}
                />
              ),
            })),
          {
            id: "logout",
            label: props?.LogoutLabel,
            icon: (
              <FHInlineSvg
                className={classes.headerBtn}
                src={props?.LoginLogoutIcon?.data?.attributes?.url as string}
                size={"24px"}
                color={"white"}
              />
            ),
          },
        ],
        onSelectedItem: (item) => {
          if (item.id === "logout") {
            setLogoutModalOpen(true);
          } else {
            window.location.href = item?.id;
          }
        },
      };

      const loggedOutHeaderProps = {
        href: updateAbsoluteLink(
          props?.LoginPageURL?.data?.attributes?.PageRoute as string,
        ),
      };
      return (
        <HeaderButton
          {...(isAuthenticated && !isAuthenticating
            ? loggedInHeaderProps
            : loggedOutHeaderProps)}
          disableElevation
          variant="outlined"
          startIcon={
            <FHInlineSvg
              className={classes.headerBtn}
              src={
                isAuthenticated
                  ? (props?.MyAccountIcon?.data?.attributes?.url as string)
                  : (props?.LoginLogoutIcon?.data?.attributes?.url as string)
              }
              size="24px"
              color="white"
            />
          }
        >
          <strong>
            {isAuthenticated ? props?.MyAccountLabel : loginLabel}
          </strong>
        </HeaderButton>
      );
    } else {
      return (
        <>
          {buttons &&
            buttons.map((btn: any, index: number) => (
              <>
                <HeaderButton
                  href={updateAbsoluteLink(
                    btn?.LinkURL?.data?.attributes?.PageRoute as string,
                  )}
                  disableElevation
                  variant="outlined"
                  key={`header-button-index-${index}`}
                  startIcon={
                    <FHInlineSvg
                      className={classes.headerBtn}
                      src={btn?.Icon?.data?.attributes?.url as string}
                      size="24px"
                      color="white"
                    />
                  }
                >
                  <Hidden mdDown>
                    <strong>{btn?.LinkText}</strong>
                  </Hidden>
                </HeaderButton>
              </>
            ))}
        </>
      );
    }
  };

  const handleLogout = () => {
    const url = `${process.env.NEXT_PUBLIC_BASE_URL}${router.asPath.replace(
      "/",
      "",
    )}`;
    const referrer = isBrowser ? document.referrer : "";
    resetBookingFunnel({ url, referrer });
    dispatch(createLogoutFromSession());
    dispatch(resetExperimentAddressData());
  };

  const LogoutDescription = () => {
    return (
      <RichContentArea>
        <div>{props?.LogoutDescription}</div>
      </RichContentArea>
    );
  };

  const uniqueMobileHeaderData =
    mobileHeaderData &&
    Array.from(
      new Set(mobileHeaderData.map((subNavItem) => subNavItem.text)),
    ).map((subNavItem) => {
      return mobileHeaderData.find(
        (subNavListItem) => subNavListItem.text === subNavItem,
      );
    });

  const showBannerSticky = () => {
    if (!isBrowser) return !hideSubscribeSticky;
    const hasButtonsThatTriggerCookie =
      !!stickyBanner?.CallToActionLink?.data?.attributes?.PageRoute ||
      !!stickyBanner?.CloseIcon?.data?.attributes?.url;
    return !!stickyBanner &&
      ((hasButtonsThatTriggerCookie && !cookies.hideSubscribeSticky) ||
        !hasButtonsThatTriggerCookie)
      ? true
      : false;
  };

  return (
    <>
      <Header
        Logo={() =>
          props?.LogoImage?.data?.attributes?.url && (
            <a href={updateAbsoluteLink("/")}>
              <FHNextImage
                src={
                  !!props?.isGlentressLandingPageExp
                    ? require("../../../stories/assets/fh_black_logo.svg")
                    : props?.LogoImage?.data?.attributes?.url
                }
                width={150}
                height={56}
                alt="Forest Holidays"
                isStaticImg={!!props?.isGlentressLandingPageExp}
                priority
              />
            </a>
          )
        }
        isBrowser={isBrowser}
        Buttons={() => createDesktopButtons()}
        links={headerData}
        mobileLinks={uniqueMobileHeaderData as SubNavLinkItem[]}
        theme="light"
        bookNowLabel={
          expandedBookNowButton
            ? expandedBookNowButton[0]?.bookNowButtonLabel
            : undefined
        }
        bookNowStyle={
          (expandedBookNowButton &&
            expandedBookNowButton[0]?.bookNowButtonTheme) as ButtonStyle
        }
        bookNowBookingWidget={
          expandedBookNowButton && (
            <DynamicBookingWidget
              {...expandedBookNowButton?.[0]?.bookingWidgetRef}
              additionalProps={
                ({
                  reduceDropdownButtonInactiveWeight:
                    !!props?.additionalProps
                      ?.reduceDropdownButtonInactiveWeight,
                  bookingWidgetDropdownBtnShadow:
                    !!props?.additionalProps?.bookingWidgetDropdownBtnShadow,
                  removeDateLabelBookingWidget:
                    !!props?.additionalProps?.removeDateLabelBookingWidget,
                  searchRemoveOptionsFromGuests:
                    !!props?.additionalProps?.searchRemoveOptionsFromGuests,
                  calendarBlackOutlines:
                    !!props?.additionalProps?.calendarBlackOutlines,
                  swapColourOnLocationSelectionControls:
                    !!props.additionalProps
                      ?.swapColourOnLocationSelectionControls,
                  changeCheckAvaiabilityToBookNow:
                    !!props.additionalProps?.changeCheckAvaiabilityToBookNow,
                  guestLocationInputTextChange:
                    !!props.additionalProps?.guestLocationInputTextChange,
                  showNewLocationTag:
                    !!props.additionalProps?.showNewLocationTag,
                  cabinsAboveGuestsInModal:
                    !!props.additionalProps?.cabinsAboveGuestsInModal,
                  selectAllEachCountry:
                    !!props.additionalProps?.selectAllEachCountry,
                  moveCheckboxesToRight:
                    !!props?.additionalProps?.moveCheckboxesToRight,
                  useSearchIconOnSearchCTA:
                    !!props?.additionalProps?.useSearchIconOnSearchCTA,
                  isGlentressLandingPageExp: !!props?.isGlentressLandingPageExp,
                  wrapLocationTextSearchForm:
                    !!props?.additionalProps?.wrapLocationTextSearchForm,
                  breconNaming: !!props?.additionalProps?.breconNaming,
                  saveDateSelection:
                    !!props?.additionalProps?.saveDateSelection,
                  exposeSearchForm: !!props?.additionalProps?.exposeSearchForm,
                } as {}) || undefined
              }
            />
          )
        }
        bookNowMobileLabel={
          expandedBookNowButton && expandedBookNowButton[0]?.bookNowLabel
        }
        headerAlwaysVisible={props?.AlwaysVisibleHeader}
        setHoverLocationId={(id) => setHoverLocationId(id)}
        hoverLocationId={hoverLocationId}
        locationMap={locationMap}
        sortedLocations={sortedLocations}
        tabs={tabs}
        viewAllLocation={viewAllLocation}
        isBannerStickyBar={!!stickyBanner}
        bannerStickyBar={
          showBannerSticky() &&
          !excludeSignUpBanner && (
            <BannerSticky
              message={
                excludeOfferFromSignUpBanner
                  ? "Be the first to know - sign up and receive forest news & offers by email"
                  : stickyBanner?.MessageLine1
              }
              messageLine2={stickyBanner?.MessageLine2}
              stickyBackgroundColour={
                stickyBanner?.BackgroundColour?.data?.attributes?.ColorCode ||
                "#2B2B2B"
              }
              stickyTextColour={
                stickyBanner?.TextColour?.data?.attributes?.ColorCode ||
                "#FBFBFB"
              }
              primaryCTALabel={stickyBanner?.CallToActionText}
              primaryCTABackgroundColour={
                stickyBanner?.CallToActionBackgroundColour?.data?.attributes
                  ?.ColorCode
              }
              primaryCTATextColour={
                stickyBanner?.CallToActionTextColour?.data?.attributes
                  ?.ColorCode
              }
              primaryCTAUrl={
                updateAbsoluteLink(
                  stickyBanner?.CallToActionLink?.data?.attributes?.PageRoute,
                ) || "/"
              }
              closeButtonIcon={
                !!stickyBanner?.CloseIcon?.data?.attributes?.url ? (
                  <FHInlineSvg
                    className={classes.headerBtn}
                    src={
                      stickyBanner?.CloseIcon?.data?.attributes?.url as string
                    }
                    size="20px"
                    color="white"
                  />
                ) : undefined
              }
              onCloseBtnClick={handleCookie}
            />
          )
        }
        singleMobileLink={
          props?.HeaderButtons?.length === 0 &&
          ([
            {
              text: loginLabel,
              isActive: false,
              link:
                !isAuthenticated && !isAuthenticating
                  ? props?.LoginPageURL?.data?.attributes?.PageRoute
                  : [
                      ...mobileMenuLinks,
                      props?.LogoutLabel && {
                        text: props?.LogoutLabel,
                        link: () => setLogoutModalOpen(true),
                        isActive: false,
                      },
                    ],
            },
          ] as SubNavLinkItem[])
        }
        hideBookNowButton={additionalProps?.hideBookNowButton}
        fullWidthSideMenu={additionalProps?.fullWidthSideMenu}
        removeTitleBookingWidgetDrawer={
          additionalProps?.removeTitleBookingWidgetDrawer
        }
        isDarkTheme={!!props?.isGlentressLandingPageExp}
        hideContactUsBtn={hideContactUsBtn}
      />
      <FHModal
        open={logoutModalOpen}
        handleClose={() => setLogoutModalOpen(false)}
        handleConfirm={handleLogout}
        title={props?.LogoutLabel}
        description={LogoutDescription()}
        confirmCTALabel={props?.LogoutConfirmCTALabel}
        cancelCTALabel={props?.LogoutCancelCTALabel}
      />
    </>
  );
};

const HeaderComponentTemplate = ({
  content,
  additionalProps,
  hideSubscribeSticky,
  isGlentressLandingPageExp = false,
}): JSX.Element => {
  const pageImgQuality = content?.pageImgQuality;
  return withContent(
    HeaderComponent,
    content,
    undefined,
    pageImgQuality,
    additionalProps,
    hideSubscribeSticky,
    isGlentressLandingPageExp,
  );
};
export default HeaderComponentTemplate;
